import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import "swiper/css";
import BoxedTitle from "../../components/boxed-title/boxed-title";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../components/my-modal/my-modal";
import Navbar from "../../components/navbar/navbar";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import { post } from "../../services/http-service";
import {
  ENDPOINT_POST_CREATE_FREE_ITINERARY_TOKEN,
  TAG_IMAGES_FOLDER,
} from "../../variables";
import styles from "./selected-itinerary-intro-page.module.css";

const SelectedItineraryIntroPage = () => {
  const userContext = useContext(UserContext);
  const selectionContext = useContext(SelectionContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);
  const [unlockState, setUnlockState] = useState("not_sent");

  useEffect(() => {
    if (location.pathname !== Routes.SELECTED_ITINERARY_INTRO) return;

    if (!selectionContext.selectedItinerary || !selectionContext.selectTag) {
      if (!isPending) setIsPending(true);
    } else if (isPending) setIsPending(false);
    setUnlockState("not_sent");
  }, [location, selectionContext]);

  async function unlockItinerary() {
    // setFetchError(false)
    // setPending(true)
    setUnlockState("pending");
    try {
      const res = await post<string>({
        endpoint: ENDPOINT_POST_CREATE_FREE_ITINERARY_TOKEN,
        bearerToken: userContext.user.token,
        data: {
          itineraryId: selectionContext.selectedItinerary?.id,
          userId: userContext.user.id,
        },
      });
      setUnlockState("success");
    } catch (err) {
      console.log(err);
      setUnlockState("failure");
      // setFetchError(true)
    }
    // setPending(false)
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar toolbarColor="primary" nextButtonDisabled={true} />
      </IonHeader>
      <IonContent>
        <div className={styles.viewContainer}>
          {isPending ? (
            <IonSpinner />
          ) : (
            <>
              <div className={styles.upperSectionContainer}>
                <p className={`${styles.upperDescription} ion-text-uppercase`}>
                  {t("itinerarySelection.chooseItinerary")}:
                </p>
                <BoxedTitle className={styles.itineraryTitle}>
                  {selectionContext.selectedItinerary?.locales[0].name}
                </BoxedTitle>
              </div>
              <div className={styles.bottomSectionContainer}>
                <p
                  className={`zonzo-scrollbar-secondary ${styles.itineraryDescription
                    } ${isPlatform("android")
                      ? styles.itineraryDescriptionAndroid
                      : ""
                    }`}
                  dangerouslySetInnerHTML={{
                    __html:
                      selectionContext.selectedItinerary?.locales[0].description ?? "",
                  }}
                ></p>
                {userContext.user.status === UserStatus.NOT_LOGGED ? (
                  <p className={`${styles.loginToContinue} ion-text-uppercase`}>
                    {t("itinerarySelection.loginToContinue")}
                  </p>
                ) : selectionContext.selectedItinerary?.cost === 0 ? (
                  <MyButtonWithBorder
                    onClick={unlockItinerary}
                    size="default"
                    color="secondary"
                    className={`${styles.purchaseButton} ion-text-uppercase`}
                  >
                    {t("ui.unlock")}
                  </MyButtonWithBorder>
                ) : (
                  <MyButtonWithBorder
                    routerLink={Routes.PAYMENT}
                    size="default"
                    color="secondary"
                    className={`${styles.purchaseButton} ion-text-uppercase`}
                  >
                    {t("ui.purchase")}
                  </MyButtonWithBorder>
                )}
              </div>
            </>
          )}
        </div>
        <MyModal
          containerClassName={styles.unlockSuccessModal}
          isOpen={unlockState !== "not_sent"}
        >
          {unlockState === "success" ? (
            <>
              <LazyLoadImage
                className={styles.unlockSuccessModalImage}
                src="assets/payment/present.svg"
                placeholder={<IonSpinner />}
              ></LazyLoadImage>
              <p>{t("itinerarySelection.itineraryUnlocked")}</p>
              <MyButtonWithBorder
                routerLink={Routes.USER_ITINERARIES}
                className={`${styles.unlockSuccessModalButton} ion-text-uppercase`}
                color="secondary"
              >
                {t("ui.play")}
              </MyButtonWithBorder>
            </>
          ) : unlockState === "failure" ? (
            <>
              <p>{t("itinerarySelection.unlockError")}</p>
              <MyButtonWithBorder
                routerLink={Routes.USER_ITINERARIES}
                className={`${styles.unlockSuccessModalButton} ion-text-uppercase`}
                color="secondary"
              >
                {t("ui.back")}
              </MyButtonWithBorder>
            </>
          ) : (
            <IonSpinner />
          )}
        </MyModal>
      </IonContent>
    </IonPage>
  );
};
export default SelectedItineraryIntroPage;
